import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Incoming students often ask me to recommend resources for getting started with Python. `}</p>
    <h1 {...{
      "id": "free-courses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#free-courses",
        "aria-label": "free courses permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Free courses`}</h1>
    <p>{`Here are some options for free, self-paced introductory courses to using Python ( I am not the author of any of these):`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://open.openclass.ai/classes/python-foundations",
          "target": "_self",
          "rel": "nofollow"
        }}>{`OpenClass's Intro to Python`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.udacity.com/course/introduction-to-python--ud1110",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Udacity's Intro to Python`}</a>{` (originally taught by `}<a parentName="li" {...{
          "href": "https://www.cs.virginia.edu/~evans/courses/cs101/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Dave Evans`}</a>{`)`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.udacity.com/course/design-of-computer-programs--cs212",
              "target": "_self",
              "rel": "nofollow"
            }}>{`Peter Norvig's followup course`}</a>{` is excellent for taking your skills to the next level`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      